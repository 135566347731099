import {
    ActionReducerMap,
    ActionReducer,
    MetaReducer, createFeatureSelector, createSelector, Action, combineReducers,
} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import * as fromLayout from './layout.reducer';
import {AuthState} from '../../auth/reducers';
import * as fromRoot from '../../reducers';
import {UploadState} from '../../upload/reducers';

export interface Layout {
    layout: fromLayout.State;
}
export interface State extends fromRoot.State {
    layout: Layout;
}

export function reducers(state: Layout | undefined, action: Action) {
    return combineReducers({
        layout: fromLayout.reducer
    })(state, action);
}



// export const selectAuthState = createFeatureSelector<AuthState>('auth');
//
// export const selectAuthStatusState = createSelector(
//     selectAuthState,
//     (state: AuthState) => state.status
// );


export const getLayoutState = createFeatureSelector<Layout>('layout');

export const selectLayoutStatusState = createSelector(
    getLayoutState,
    (state: Layout) => state.layout
);


export const getCurrentLanguage = createSelector(
    selectLayoutStatusState,
    fromLayout.getLanguage
);





