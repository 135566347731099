import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/services/auth-guard.service';
import {UploadPageComponent} from './containers/upload-page.component';
import {LanguageResolver} from '../core/services/languageResolver.service';


const routes: Routes =
    [
        {path: 'upload',
        component: UploadPageComponent, canActivate: [AuthGuard],
        resolve: { navigationResult: LanguageResolver } ,   data: {logged: false} },
        {path: ':language/upload',
            component: UploadPageComponent, canActivate: [AuthGuard],
            resolve: { navigationResult: LanguageResolver } ,   data: {logged: false} }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UploadRoutingModule {
}
