import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {EffectsModule} from '@ngrx/effects';
import {UploadPageComponent} from './containers/upload-page.component';
import {UploadComponent} from './components/upload.component';
import {UploadRoutingModule} from './upload-routing.module';
import {UploadEffects} from './effects/upload.effects';
import {BrowserModule} from '@angular/platform-browser';
import {FileComponent} from './components/file.component';
import {TranslateModule} from '@ngx-translate/core';

export const COMPONENTS = [
    UploadPageComponent,
    UploadComponent,
    FileComponent,
];

@NgModule({
    imports: [
        BrowserModule,
        TranslateModule,
        UploadRoutingModule,
        StoreModule.forFeature('upload', reducers),
        EffectsModule.forFeature([UploadEffects])
    ],
    declarations: COMPONENTS
})
export class UploadModule {
}
