import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AuthModule} from './auth/auth.module';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './auth/effects/auth.effects';
import {reducers, metaReducers} from './reducers';
import {AppComponent} from './core/containers/app.component';
import {HttpClientModule} from '@angular/common/http';
import {UploadModule} from './upload/upload.module';
import {LanguageResolver} from './core/services/languageResolver.service';
import {CoreModule} from './core/core.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AuthModule,
        UploadModule,
        HttpClientModule,
        CoreModule,
        TranslateModule.forRoot(),
        BrowserModule,
        AppRoutingModule,
        StoreModule.forRoot(reducers, {metaReducers, runtimeChecks: {strictStateImmutability: true, strictActionImmutability: true}}),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            name: 'Media Uploader'
        }),
        EffectsModule.forRoot([AuthEffects])
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
