import { createAction, props } from '@ngrx/store';

export const getHash = createAction('[I18n] Get Hash');
export const getTranslationDictionary = createAction('[I18n] Get Translation Dictionary');

export const getHashSuccess = createAction('[I18n API] Get Hash Success', props<{ hash: string | null }>());
export const getHashFailure = createAction('[I18n API] Get Hash Failure', props<{ error: string }>());

export const getTranslationDictionarySuccess = createAction('[I18n API] Get Translation Dictionary Success', props<{ dictionary: any }>());
export const getTranslationDictionaryFailure = createAction('[I18n API] Get Translation Dictionary Failure', props<{ error: string }>());

export const setHash = createAction('[I18n] Set Hash', props<{ hash: string | null }>());
export const loadTranslationCache = createAction('[I18n] Load Cache');

export const setLanguage = createAction('[I18n] Set Language', props< {language: string}>());
export const setLanguageSuccess = createAction('[I18n] Set Language Success', props< {language: string}>());
