import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

/**
 * Checks if user has up to date translations and updates translations dictionary if necessary.
 */
@Injectable({
  providedIn: 'root',
})
export class I18nService {
  /**
   * Creates new service instance.
   * @param http
   */
  constructor(private http: HttpClient) {}

  /**
   * Gets newest dictionary checksum.
   * @returns Hash string
   */
  public getHash(): Observable<string> {
    const params = new HttpParams().set('grant_type', 'translation');
    return this.http.get<any>('/assets/data/hash.json', {params}).pipe(
      map((res: any) => {
        return res.hash;
      }),
      catchError((error: string) => {
       console.log(error);
       return  throwError(() => new Error(error)) }
    ));
  }

  /**
   * Gets translations dictionary from server.
   * @returns Translations dictionary.
   */
  public getTranslations(): Observable<any> {
    let params = new HttpParams().set("grant_type", "translation");
    return this.http.get<any>('/assets/data/translations.json', {params}).pipe(
      map((res: any) => res),
      catchError((error: string) => throwError(() => new Error(error)))
    );
  }
}
