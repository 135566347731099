import { createAction, props} from '@ngrx/store';
import {Storage} from '../models/storage';

export const GetStorage = createAction(
    '[Upload Page] Get Storage',
);
export const  Upload  = createAction(
    '[Upload Page] Upload',
    props<{payload: any}>()
);




// export enum UploadPageActionTypes {
//     GetStorage = '[Upload Page] Get Storage',
//     Upload = '[Upload Page] Upload'
// }

// export class GetStorage implements Action {
//     readonly type = UploadPageActionTypes.GetStorage;
// }
//
// export class Upload implements Action {
//     readonly type = UploadPageActionTypes.Upload;
//     constructor(public payload: any) {}
// }
//
// export type UploadPageActionsUnion = GetStorage | Upload;
