import { createAction, props } from '@ngrx/store';
import {Token, Credentials} from '../models/auth';

export const LoginSuccess = createAction(
    '[Auth API] Login Success',
    props<{payload: Token}>()
);
export const  LoginFailure  = createAction(
    '[Auth/API] Login Failure',
    props<{payload: Credentials}>()
);
export const  LoginRedirect  = createAction(
    '[Auth/API] Login Redirect',
);
export const  RefreshTokenSuccess  = createAction(
    '[Auth/API] Refresh Token Success',
    props<{payload: Token}>()
);
export const  ClientCredentialsTokenSuccess  = createAction(
    '[Auth/API] Client Credentials Token Success',
    props<{payload: Token}>()
);





export enum AuthApiActionTypes {
    LoginSuccess = '[Auth/API] Login Success',
    LoginFailure = '[Auth/API] Login Failure',
    LoginRedirect = '[Auth/API] Login Redirect',
    RefreshTokenSuccess = '[Auth/API] Refresh Token Success',
    ClientCredentialsTokenSuccess = '[Auth/API] Client Credentials Token Success',
}

// export class LoginSuccess implements Action {
//     readonly type = AuthApiActionTypes.LoginSuccess;
//     constructor(public payload: Token) {}
// }
//
// export class LoginFailure implements Action {
//     readonly type = AuthApiActionTypes.LoginFailure;
//     constructor(public payload: Credentials) {}
// }
//
// export class LoginRedirect implements Action {
//     readonly type = AuthApiActionTypes.LoginRedirect;
// }
//
// export class RefreshTokenSuccess implements Action {
//     readonly type = AuthApiActionTypes.RefreshTokenSuccess;
//     constructor(public payload: Token) {}
// }
//
// export class ClientCredentialsTokenSuccess implements Action {
//     readonly type = AuthApiActionTypes.ClientCredentialsTokenSuccess;
//     constructor(public payload: Token) {}
// }

// export type AuthApiActionsUnion =
//     LoginSuccess |
//     LoginFailure |
//     LoginRedirect |
//     RefreshTokenSuccess |
//     ClientCredentialsTokenSuccess;

