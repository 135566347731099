import {Action, createAction, props} from '@ngrx/store';
import {Credentials, Token} from '../models/auth';

export const Login = createAction(
    '[Login Page] Login',
    props<{payload: Credentials}>()
);






// export enum LoginPageActionTypes {
//     Login = '[Login Page] Login',
// }
//
// export class Login implements Action {
//     readonly type = LoginPageActionTypes.Login;
//     constructor(public payload: Credentials) {}
// }
//
// export type LoginPageActionsUnion = Login;
