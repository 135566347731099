import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, concatMap, takeUntil, catchError, exhaustMap, switchMap} from 'rxjs/operators';
import {UploadPageActions, UploadApiActions} from '../actions';
import {UploadService} from '../services/upload.service';
import {Storage} from '../models/storage';
import {of} from 'rxjs';
import {HttpEvent, HttpEventType} from '@angular/common/http';
@Injectable()
export class UploadEffects {


    storage$  = createEffect(() =>
        this.actions$.pipe(
        ofType(UploadPageActions.GetStorage),
        switchMap(() =>
            this.uploadService.storage().pipe(
                map((storage: Storage) => UploadApiActions.StorageSuccess({payload: storage})),
                catchError((error: string) => of(UploadApiActions.StorageFailure({payload: error}))))
        )
        )
    );


    upload$ = createEffect(() =>
        this.actions$.pipe(
        ofType(UploadPageActions.Upload),
        concatMap(action =>
            this.uploadService.upload(action.payload).pipe(
                map(event => this.getActionFromHttpEvent(event)),
                catchError(error => of(this.handleError(error)))
            )
        )
        )
    );

    private getActionFromHttpEvent(event: HttpEvent<any>) {
        switch (event.type) {
            case HttpEventType.Sent: {
                return  UploadApiActions.UploadStart();
            }
            case HttpEventType.UploadProgress: {
                return  UploadApiActions.UploadProgress({
                  payload : { progress: Math.round((100 * event.loaded) / event.total)}
                });
            }
            case HttpEventType.Response: {
                if (event.status === 200) {
                    return  UploadApiActions.UploadSuccess();
                } else {
                    return  UploadApiActions.UploadFailure({
                       payload:  { error: event.statusText}
                    });
                }
            }
            default: {
                return  UploadApiActions.Default({ payload: event.type});
            }
        }
    }


    private handleError(error: any) {
        return  UploadApiActions.UploadFailure({payload: error});
    }

    constructor(
        private actions$: Actions,
        private uploadService: UploadService
    ) {}
}
