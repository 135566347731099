import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, FormGroup} from '@angular/forms';
import {Credentials} from '../models/auth';
import {Utils} from '../../common/utils';

@Component({
    selector: 'app-login-form',
    template: `
    <div class="container">
<!--        <h2>Zaloguj się loginem i hasłem-->
<!--          z eminto.eu</h2>-->
        <h2>{{ 'LOGIN/TITLE' | translate  }}</h2>
        <form [formGroup]="loginForm" (submit)="submit()">
            <input    [placeholder]="'LOGIN/EMAIL' | translate "   id="login" type="text" [ngClass]="{'ng-invalid': error}" formControlName="email" email required>
            <input [placeholder]="'LOGIN/PASSWORD' | translate "    id="password" type="password" [ngClass]="{'ng-invalid': error}" formControlName="password" required>
            <button type="submit" class="btn">{{ 'LOGIN/BTN' |  translate }}</button>
        </form>
    </div>`,
    styles: [
        `
          button {
            margin-top: 45px;
          }
          .container  {
            margin: 0 auto;
          }
     form .ng-invalid.ng-touched {
        border-bottom: 1px solid red;
      }
    `
    ]
})
export class LoginFormComponent {
    @Input() error: Credentials | null;
    @Output() submitted = new EventEmitter<Credentials>();
    loginForm: FormGroup = new FormGroup({
        email: new UntypedFormControl(''),
        password: new UntypedFormControl('')
    });

    submit() {
        if (this.loginForm.valid) {
            this.submitted.emit(this.loginForm.value);
        }
        else {
            Utils.validateFormFields(this.loginForm);
        }
    }
}
