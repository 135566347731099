import {AuthActions, AuthApiActions, LoginPageActions} from '../actions';
import {Credentials} from '../models/auth';
import {createReducer, on} from '@ngrx/store';

export interface State {
    info: string;
    error: Credentials | null;
}

export const initialState: State = {
    info: null,
    error: null
};
export const reducer = createReducer(
    initialState,
    on(LoginPageActions.Login, (state: State) => ({...state, error: null})),
    on(AuthApiActions.LoginSuccess, (state: State) => ({...state, error: null})),
    on(AuthApiActions.LoginFailure, (state: State, action) => ({...state, error: action.payload})),
);




// export function reducer(
//     state = initialState,
//     action:
//         | AuthApiActions.AuthApiActionsUnion
//         | LoginPageActions.LoginPageActionsUnion
// ): State {
//     switch (action.type) {
//         case LoginPageActions.LoginPageActionTypes.Login:
//         case AuthApiActions.AuthApiActionTypes.LoginSuccess: {
//             return {
//                 ...state,
//                 error: null
//             };
//         }
//
//         case AuthApiActions.AuthApiActionTypes.LoginFailure: {
//             return {
//                 ...state,
//                 error: action.payload
//             };
//         }
//
//         default: {
//             return state;
//         }
//     }
// }

export const getError = (state: State) => state.error;
