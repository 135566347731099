import {
    ActionReducerMap,
    ActionReducer,
    MetaReducer, createFeatureSelector, createSelector, Action, combineReducers,
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import {localStorageSync} from 'ngrx-store-localstorage';
import * as fromLayout from '../core/reducers/layout.reducer';
import * as fromAuth from '../auth/reducers/auth.reducer';
import * as fromLoginPage from '../auth/reducers/login-page.reducer';
import {AuthState} from '../auth/reducers';


export interface State {
    router: fromRouter.RouterReducerState;
}

// export function reducers(state: AuthState | undefined, action: Action) {
//     return combineReducers({
//         status: fromAuth.reducer,
//         loginPage: fromLoginPage.reducer,
//     })(state, action);
// }





export const reducers: ActionReducerMap<State> = {
    router: fromRouter.routerReducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state: State, action: any): any => {
        const result = reducer(state, action);
        console.groupCollapsed(action.type);
        console.log('prev state', state);
        console.log('action', action);
        console.log('next state', result);
        console.groupEnd();

        return result;
    };
}

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
    return localStorageSync({keys: ['auth'], rehydrate: true})(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger, localStorageSyncReducer]
    : [localStorageSyncReducer];







