import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import {Store, select} from '@ngrx/store';
import {combineLatest, Observable, withLatestFrom} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthApiActions} from '../actions';
import * as fromAuth from '../reducers';
import * as fromLayout from '../../core/reducers';
import {Token} from '../models/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private store: Store<fromAuth.State>, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
     const logged = route.data.logged as boolean;
     const paramLanguage = route.paramMap.get('language') as string;
     console.log('can activate' + paramLanguage);

     // return this.store.pipe(
     //        select(fromAuth.getToken),
     //        withLatestFrom(this.store.pipe(select(fromAuth.getLogged))),
     return combineLatest([
            this.store.pipe(select(fromAuth.getToken)),
            this.store.pipe(select(fromLayout.getCurrentLanguage))
        ]).pipe(
            map(([token, language]) => {
                if (token && token.refresh_token && logged) {
                    const lang = paramLanguage ?? window.navigator.language.slice(0, 2);
                    this.router.navigate([`${lang}/upload`]);
                    return false;
                }
                else if (!token && !logged) {
                    this.store.dispatch(AuthApiActions.LoginRedirect());
                    return false;
                }
                return true;
            }),
            take(1)
        );
    }
}
