import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpEvent, HttpRequest, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Storage} from '../models/storage';
import {apiUrl} from 'src/app/common/api-url';
@Injectable({
    providedIn: 'root',
})
export class UploadService {
    constructor(private http: HttpClient) {}
    public storage(): Observable<Storage>{
        return this.http.post<Storage>(`${apiUrl}/upload/user-storage`, new HttpParams())
        .pipe(
            map((response: Storage) => {
                return response;
            }),
            catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
        );
    }

    public upload(file: File): Observable<HttpEvent<{}>> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        const req = new HttpRequest(
            'POST',
            `${apiUrl}/upload`,
            formData,
            {reportProgress: true}
        );
        return this.http.request(req);
    }
}
