import { createAction, props } from "@ngrx/store";



export const setTranslationComplate = createAction(
  '[Layout] Set Translation Complete'
);
export const setTranslationInComplate = createAction(
  '[Layout] Set Translation InComplete'
);

