import {Component, Input, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-file-item',
    template: `
    <div class="file" [ngClass]="class">
        <p class="name">{{name}}</p>
        <span class="btn-reupload" *ngIf="class == 'warning'" (click)="reupload.emit(id)">spróbuj ponownie</span>
        <p class="status">{{status}}</p>
        <span class="edit" (click)="click()"></span>
        <div class="progress">
            <div class="bar" [style.width]="progress +'%'"></div>
        </div>
    </div>`,
    styles: [
        `
          .file {
              position: relative
          }

          .file .name {
              margin-bottom: 0;
              color: #fff;
          }

          .file .edit {
              right: 3px;
              position: absolute;
              top: 20px;
          }

          .file .status {
              color: #A8A8A8;
              margin-top: 0;
              margin-bottom: 5px;
              font-size: 14px;
          }

          .file .edit {
              display: block;
              width: 16px;
              height: 16px;
              background: #A0A0A0;
              border-radius: 50%;
              background-size: 16px;
          }

          .file.done .edit {
              background-image: url('/assets/img/icons/done.svg');
          }

          .file.remove .edit {
              cursor:pointer;
              background-image: url('/assets/img/icons/cancel.svg');
          }

          .file.warning .edit {
              background-image: url('/assets/img/icons/warning.svg');
          }

          .file.in-progress .edit {
              -webkit-animation: rotating 2s linear infinite;
              -moz-animation: rotating 2s linear infinite;
              -ms-animation: rotating 2s linear infinite;
              -o-animation: rotating 2s linear infinite;
              animation: rotating 2s linear infinite;
              background-image: url('/assets/img/icons/inprogress.svg');
          }

          .btn-reupload{
              font-size:10px;
              right: 25px;
              margin-top: -2px;
              position:absolute;
              cursor:pointer;
          }

          .progress {
              overflow: hidden;
              height: 17px;
                border: 1px solid #fff;

              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              border-radius: 50px;
          }

          .progress .bar {
            width: 50%;
              height: 100%;
            background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
            -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              -webkit-transition: width 0.6s ease;
              -moz-transition: width 0.6s ease;
              -ms-transition: width 0.6s ease;
              -o-transition: width 0.6s ease;
              transition: width 0.6s ease;
          }

          .done .progress .bar{
              background-color: #7ED321;
          }

          @-webkit-keyframes rotating {
              from {
                  -webkit-transform: rotate(0deg);
                  -o-transform: rotate(0deg);
                  transform: rotate(0deg);
              }

              to {
                  -webkit-transform: rotate(360deg);
                  -o-transform: rotate(360deg);
                  transform: rotate(360deg);
              }
          }

          @keyframes rotating {
              from {
                  -ms-transform: rotate(0deg);
                  -moz-transform: rotate(0deg);
                  -webkit-transform: rotate(0deg);
                  -o-transform: rotate(0deg);
                  transform: rotate(0deg);
              }

              to {
                  -ms-transform: rotate(360deg);
                  -moz-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                  -o-transform: rotate(360deg);
                  transform: rotate(360deg);
              }
          }
    `
    ]
})

export class FileComponent {
    @Input() id: number;
    @Input() name: string;
    @Input() progress: number = 0;
    @Input() status: string;
    @Input() class: string = "remove";
    @Output() remove = new EventEmitter<number>();
    @Output() reupload = new EventEmitter<number>();

    click(): void {
        if (this.class === "remove") {
            this.remove.emit(this.id);
        }
    }
}
