import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpParams,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {switchMap, catchError} from 'rxjs/operators';
import {Token} from '../models/auth';
import * as fromAuth from '../reducers';
import {Store, select} from '@ngrx/store';
import {AuthActions, AuthApiActions} from '../actions';
import {Actions, ofType} from '@ngrx/effects';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    token: Token;
    constructor(private auth: AuthService, private store: Store<fromAuth.State>,
                private actions$: Actions<any>) {
        this.store.pipe(select(fromAuth.getToken))
            .subscribe((token: Token) => {
                if (token) {
                    this.token = token;
                }
            });
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let params: HttpParams = request.body,
         getParams: HttpParams = request.params,
            grantType = ['password', 'client_credentials', 'refresh_token', 'translation'];
        if ( (  (request.method === 'POST'  &&  grantType.includes(params.get('grant_type')) )
            || (request.method === 'GET' && getParams.get('grant_type') === 'translation')   )) {
            return next.handle(this.setHeader(request, this.auth.basicAuthorization()));
        }
        else if (params.get('grant_type') === 'basic') {
            if (this.token) {
                return next.handle(this.setHeader(request, 'Bearer ' + this.token.access_token))
                    .pipe(catchError((err: HttpErrorResponse) => {
                        if (err.status === 401) {
                            return this.clientCredentialsToken(request, next);
                        }
                        return throwError(err);
                    }));
            }
            else {
                return this.clientCredentialsToken(request, next);
            }
        }
        else {
            return next.handle(this.setHeader(request, 'Bearer ' + this.token.access_token))
                .pipe(catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                        return this.refreshToken(request, next);
                    }
                    return throwError(err);
                }));
        }
    }

    private clientCredentialsToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.store.dispatch(AuthActions.ClientCredentialsToken());
        return this.actions$.pipe(
            ofType(AuthApiActions.ClientCredentialsTokenSuccess),
            switchMap((action) => {
                return next.handle(this.setHeader(request, 'Bearer ' + action.payload.access_token))
            }));
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.store.dispatch(AuthActions.RefreshToken({payload : this.token}));
        return this.actions$.pipe(
            ofType(AuthApiActions.RefreshTokenSuccess),
            switchMap((action) => {
                return next.handle(this.setHeader(request, 'Bearer ' + action.payload.access_token))
            }));
    }

    private setHeader(request: HttpRequest<any>, authorization: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: authorization
            }
        });
    }
}
