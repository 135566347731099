import {AuthApiActions, AuthActions} from '../actions';
import {Token} from '../models/auth';
import {state} from '@angular/animations';
import {createReducer, on} from '@ngrx/store';

export interface State {
    token: Token | null;
}

export const initialState: State = {
    token: null,
};
export const reducer = createReducer(
    initialState,
    on(AuthApiActions.LoginSuccess, (state: State, action) => ({...state, token: action.payload})),
    on(AuthApiActions.RefreshTokenSuccess, (state: State, action) => ({...state, token: action.payload})),
    on(AuthApiActions.ClientCredentialsTokenSuccess, (state: State, action) => ({...state, token: action.payload})),
    on(AuthActions.Logout, (state: State, action) =>  initialState),
);




// export function reducer(
//     state = initialState,
//     action: AuthApiActions.AuthApiActionsUnion | AuthActions.AuthActionsUnion
// ): State {
//     switch (action.type) {
//         case AuthApiActions.AuthApiActionTypes.LoginSuccess:
//         case AuthApiActions.AuthApiActionTypes.RefreshTokenSuccess:
//         case AuthApiActions.AuthApiActionTypes.ClientCredentialsTokenSuccess: {
//             return {
//                 ...state,
//                 token: action.payload,
//             };
//         }
//         case AuthActions.AuthActionTypes.Logout: {
//             return initialState;
//         }
//         default: {
//             return state;
//         }
//     }
// }

// tslint:disable-next-line:no-shadowed-variable
export const getToken = (state: State) => state.token;
