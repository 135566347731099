import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {Credentials, Token} from '../models/auth';
import {HttpClient, HttpParams, HttpErrorResponse} from '@angular/common/http';
import {map, catchError} from 'rxjs/operators';
import {apiUrl} from 'src/app/common/api-url';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpClient) {}

    public login(credentials: Credentials): Observable<Token> {
        const params = new HttpParams()
            .set('username', credentials.email)
            .set('password', credentials.password)
            .set('grant_type', 'password');
        return this.post(params);
    }

    public clientCredentialsToken(): Observable<Token> {
        const params = new HttpParams()
            .set('grant_type', 'client_credentials');
        return this.post(params);
    }

    public refreshToken(token: Token): Observable<Token> {
        const params = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', token.refresh_token + '|' + token.access_token);
        return this.post(params);
    }

    private post(params: HttpParams): Observable<Token> {
        return this.http.post<Token>(`${apiUrl}/oauth/token`, params)
            .pipe(
                map((token: Token) => {
                    return token;
                }),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    public basicAuthorization(): string {
        return 'Basic dXBsb2FkZXI6Y3NKVVd4RFFaR2pUcEpVdk9SZkVFWENwVEVZQmFrTmM';
    }
}
