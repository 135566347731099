import { PipeTransform, Injectable } from '@angular/core';

@Injectable()
export class FileSizePipe implements PipeTransform {
    transform(size: number, unit:string = "GB") {
        var map = {
            mb: 1 << 20,
            gb: 1 << 30
        };
        return parseFloat((size / map[unit.toLowerCase()]).toFixed(2));
    }
}