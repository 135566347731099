import {Action, createAction, props} from '@ngrx/store';
import {Storage} from '../models/storage';
import {Token} from '../../auth/models/auth';


export const StorageSuccess = createAction(
    '[Upload/API] Storage Success',
    props<{payload: Storage}>()
);
export const  StorageFailure  = createAction(
    '[Upload/API] Storage Failure',
    props<{payload: string}>()
);
export const  UploadStart  = createAction(
    '[Upload/API] Upload Start',
);
export const  UploadProgress  = createAction(
    '[Upload/API] Upload Progress',
    props<{payload: {progress: number}}>()
);

export const  UploadSuccess  = createAction(
    '[Upload/API] Upload Success',
);

export const  UploadFailure  = createAction(
    '[Upload/API] Upload Failure',
    props<{payload: {error: string}}>()
);
export const  Default  = createAction(
    '[Upload/API] Default',
    props<{payload: number}>()
);





// export enum UploadApiActionTypes {
//     StorageSuccess = '[Upload/API] Storage Success',
//     StorageFailure = '[Upload/API] Storage Failure',
//     UploadStart = '[Upload/API] Upload Start',
//     UploadProgress = '[Upload/API] Upload Progress',
//     UploadSuccess = '[Upload/API] Upload Success',
//     UploadFailure = '[Upload/API] Upload Failure',
//     Default = '[Upload/API] Default'
// }

// export class StorageSuccess implements Action {
//     readonly type = UploadApiActionTypes.StorageSuccess;
//     constructor(public payload: Storage) {}
// }
//
// export class StorageFailure implements Action {
//     readonly type = UploadApiActionTypes.StorageFailure;
//     constructor(public payload: string) {}
// }
//
// export class UploadStart implements Action {
//     readonly type = UploadApiActionTypes.UploadStart;
// }
//
// export class UploadProgress implements Action {
//     readonly type = UploadApiActionTypes.UploadProgress;
//     constructor(public payload: {progress: number}) {}
// }
//
// export class UploadSuccess implements Action {
//     readonly type = UploadApiActionTypes.UploadSuccess;
// }
//
// export class UploadFailure implements Action {
//     readonly type = UploadApiActionTypes.UploadFailure;
//     constructor(public payload: {error: string}) {}
// }
//
// export class Default implements Action {
//     readonly type = UploadApiActionTypes.Default;
//     constructor(public payload: number) {}
// }

// export type UploadApiActionsUnion =
//     StorageSuccess |
//     StorageFailure |
//     UploadStart |
//     UploadProgress |
//     UploadSuccess |
//     UploadFailure;
