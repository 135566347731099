import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as fromLayout from '../../reducers';
import { I18nActions, LayoutActions } from '../actions';


@Injectable({ providedIn: 'root' })
export class LanguageResolver  {

    languages = ['pl', 'en', 'de', 'sk'];

    constructor(
        private translate: TranslateService,
        private store: Store<fromLayout.State>,
        private router: Router,
        private route: ActivatedRoute,
    ) {}
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): string | Observable<string> | Promise<string> {
        const paramLanguage = route.paramMap.get('language') as string;
        const language = paramLanguage ?? window.navigator.language.slice(0, 2);
        if (this.languages.indexOf(language) === -1) {
           // this.router.navigate(['/en'], {queryParams: route.queryParams, queryParamsHandling: 'merge'});
        }
        this.store.dispatch(I18nActions.setLanguage({ language }));
        return this.translate.use(language as string);
}
}
