import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginPageComponent} from './containers';
import {AuthGuard} from './services/auth-guard.service';
import { LanguageResolver } from '../core/services/languageResolver.service';

const routes: Routes = [{
    path: '', component: LoginPageComponent, canActivate: [AuthGuard],  resolve: { navigationResult: LanguageResolver },
    data: {logged: true}
},

    {
        path: ':language', component: LoginPageComponent, canActivate: [AuthGuard],  resolve: { navigationResult: LanguageResolver },
        data: {logged: true}
    },
    {
        path: ':language/login', component: LoginPageComponent, resolve: { navigationResult: LanguageResolver }, canActivate: [AuthGuard],
        data: {logged: true}
    },
    {
        path: 'login', component: LoginPageComponent, resolve: { navigationResult: LanguageResolver }, canActivate: [AuthGuard],
        data: {logged: true}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
