import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';



import { I18nEffects } from './effects/i18n.effects';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
// import { LayoutEffects } from './effects/layout.effects';
// import { NoOrphansPipe } from './pipes/no-orphans.pipe';


const COMPONENTS = [

];
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    StoreModule.forFeature('layout', reducers),
    EffectsModule.forFeature([I18nEffects]),
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class CoreModule {}
