import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, switchMap, withLatestFrom} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {LoginPageActions, AuthApiActions, AuthActions} from '../actions';
import {Credentials, Token} from '../models/auth';
import {AuthService} from '../services/auth.service';
import {Utils} from 'src/app/common/utils';
import {select, Store} from '@ngrx/store';
import * as fromLayout from '../../core/reducers';

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginPageActions.Login),
            exhaustMap((action) =>
                this.authService
                    .login(action.payload)
                    .pipe(
                        map((token: Token) => {
                            return AuthApiActions.LoginSuccess({payload: token});
                        }
                        ),
                        catchError((error) => of(AuthApiActions.LoginFailure(Utils.isJson(error)))
                        )
                    )
            )
        )
    );
    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthApiActions.LoginSuccess),
            withLatestFrom( this.store.pipe(select(fromLayout.getCurrentLanguage))),
            tap(([t, lang]) => {
                return this.router.navigate([`${lang ? lang : '' }/upload`]);
            })
        ), { dispatch: false }
);
    loginRedirect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthApiActions.LoginRedirect, AuthActions.Logout),
            withLatestFrom( this.store.pipe(select(fromLayout.getCurrentLanguage))),
            tap(([action, lang]) => {
                return   this.router.navigate([`${lang ? lang : '' }/login`]);
            })
        ), { dispatch: false }
    );

    clientCredentialsToken$ = createEffect(() =>
        this.actions$.pipe(
        ofType(AuthActions.ClientCredentialsToken),
        exhaustMap(() =>
            this.authService.clientCredentialsToken().pipe(
                map((token: Token) =>  AuthApiActions.ClientCredentialsTokenSuccess({payload: token}))
            )
        )
    )
    );
    refreshToken$ = createEffect(() =>
    this.actions$.pipe(
        ofType(AuthActions.RefreshToken),
        map((action) => action.payload),
        exhaustMap((token: Token) =>
            this.authService.refreshToken(token).pipe(
                map((token: Token) =>  AuthApiActions.RefreshTokenSuccess({payload: token}))
            )
        )
    )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private store : Store
    ) {}
}
