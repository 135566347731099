import {createSelector, createFeatureSelector, ActionReducerMap, combineReducers, Action} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from './auth.reducer';
import * as fromLoginPage from './login-page.reducer';
import {AuthApiActions} from '../actions';

export interface AuthState {
    status: fromAuth.State;
    loginPage: fromLoginPage.State;
}

export interface State extends fromRoot.State {
    auth: AuthState;
}
export function reducers(state: AuthState | undefined, action: Action) {
    return combineReducers({
        status: fromAuth.reducer,
        loginPage: fromLoginPage.reducer,
    })(state, action);
}
// export const reducers: ActionReducerMap<
//     AuthState,
//     AuthApiActions> = {
//     status: fromAuth.reducer,
//     loginPage: fromLoginPage.reducer,
// };
// export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);
export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(
    selectAuthState,
    (state: AuthState) => state.status
);
export const getToken = createSelector(selectAuthStatusState, fromAuth.getToken);
export const getLoggedIn = createSelector(getToken, token => !!token);

export const selectLoginPageState = createSelector(
    selectAuthState,
    (state: AuthState) => state.loginPage
);
export const getLoginPageError = createSelector(
    selectLoginPageState,
    fromLoginPage.getError
);
