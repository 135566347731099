import {Component, Input, Output, EventEmitter, SimpleChanges, SimpleChange, ViewChildren, QueryList} from '@angular/core';
import {Observable} from 'rxjs';
import {Storage} from '../models/storage';
import {FileSizePipe} from '../../common/filesize.pipe';
import {FileComponent} from './file.component';

@Component({
    selector: 'app-upload',
    template: `
    <div class="container" *ngIf="complete">
        <div class="complet_mark_ctn">
            <img src="assets/img/icons/circle.svg" alt="circle">
            <img src="assets/img/icons/line.svg" alt="line">
        </div>
        <h3 class="text-center">{{ 'UPLOAD/SUCCESS_TITLE' | translate  }}</h3>
          <h3 class="text-center">  {{  'UPLOAD/SUCCESS_TEXT' | translate }}</h3>
        <span class="btn" (click)="logout.emit()">OK</span>
    </div>
    <div class="container position-relative" *ngIf="storage && !complete">
        <div class="space">
            <h4>{{availableStorage | number:'1.00'}} / {{storage.available}} GB</h4>
            <h3>{{  'UPLOAD/AVAILABLE' | translate }}</h3>
        </div>
            <div class="dropzone-wrapper" *ngIf="count < 0">
              <div class="info" [ngClass]="double.length > 0 || sizeNotAllowed  || notAllowed || availableStorage < 0 ? 'show' : ''">
                <h4 *ngIf="availableStorage < 0">{{ 'UPLOAD/AVAILABLE_ERROR' | translate }}</h4>
                <h4 *ngIf="notAllowed">{{ 'UPLOAD/FILE_ERROR' | translate }}<br>{{ 'UPLOAD/FILE_ALLOWED' | translate }}<br>png, jpg, jpeg, mp4.</h4>
                <h4 *ngIf="sizeNotAllowed">{{ 'UPLOAD/FILE_SIZE_ERROR' | translate }}</h4>
                <div *ngIf="double.length > 0">
                  <h4>{{ 'UPLOAD/FILE_EXIST_ERROR' | translate }} </h4>
                  <div class="file">
                    <p class="name" *ngFor="let file of double">{{file}} </p>
                  </div>
                </div>
              </div>
                <div class="dropzone-desc">
                    <p>{{ 'UPLOAD/TEXT' | translate }}</p>
                </div>
                <input #fileInput type="file" class="dropzone" multiple (click)="fileInput.value = null" (change)="selectChange($event.target.files)" accept=".png, .jpg, .gif, .jpeg, .mp4">
            </div>
        <div class="files" *ngIf="files.length > 0">
            <app-file-item *ngFor="let file of files; index as i"
            #cmp [id]="i" [name]="file.name" [status]="fileSizePipe.transform(file.size, 'MB') + ' MB'" (remove)="remove($event)" (reupload)="startReupload($event)"></app-file-item>
        </div>
        <div class="wrapper" *ngIf="files.length > 0 && availableStorage > 0 && count < 0">
            <span class="btn" (click)="uploadStart()">{{ 'UPLOAD/BTN' | translate }}</span>
        </div>
    </div>`,
    styles: [
        `
            .complet_mark_ctn  {
                position: relative;
                width: 226px;
                height: 226px;
                margin: 0 auto;
            }
            .complet_mark_ctn +  h3 {
                    margin-bottom: -30px;
            }
            .complet_mark_ctn img {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                animation: show 700ms forwards;
            }
            .complet_mark_ctn img:nth-child(2) {
                scale: 0;
                width: 65%;
                height: 65%;
               animation-delay: 400ms;
            }

            @keyframes show {
                from {
                    scale: 0;
                }
                to {
                    scale: 1;
                }
            }


          .dropzone-wrapper {
              border: 2px solid rgba(255, 255, 255, 0.80);
              color: #92b0b3;
              position: relative;
              height: 223px;
              width: 100vh;
              max-width: 550px;
              margin: 0 auto 20px auto;
              border-radius: 20px;
          }

          .dropzone-desc {
                color: #fff;
            opacity: 0.8;
              position: absolute;
              margin: 0 auto;
              left: 0;
              right: 0;
              text-align: center;
              top: 50%;
              font-size: 20px;
              transform: translateY(-50%);
              font-family: Museo , sans-serif;
              font-weight: 300;
          }

          .dropzone,
          .dropzone:focus {
              position: absolute;
              outline: none !important;
              width: 100%;
              height: 150px;
              cursor: pointer;
              opacity: 0;
          }

          .dropzone-wrapper:hover,
          .dropzone-wrapper.dragover {
              background: #ecf0f5;
          }
          .dropzone-wrapper:hover p ,
          .dropzone-wrapper.dragover p {
          color: #0600FD;

          }

          .files {
              margin-bottom: 30px;
              max-height: 300px;
              width: 100vw;
              max-width: 550px;
              overflow: auto;
          }

          .files + .wrapper {
            margin-bottom: 20px;
          }

          .space {
               text-align: center;
            }

          .space h3 {
               padding: 0 0 15px 0;
               margin-top: 0;
               text-transform: uppercase;
               font-size: 34px;
                font-weight: 600;
            }

          .space h4 {
                color: #fff;
                font-size: 34px;
                margin-bottom: 0;
                margin-top:20px;
                font-family: Museo , sans-serif;
            }

          .info {
                /*background: #0A93F4;*/
                box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.20);
                border-radius: 8px;
                border: 2px solid #fff;
                width: 300px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                padding: 20px;
                color: #ffffff;
                opacity: 0;
                visibility: hidden;
                transition: opacity .25s ease-in-out;
                -moz-transition: opacity .25s ease-in-out;
                -webkit-transition: opacity .25s ease-in-out;
            }

          .info.show {
                opacity: 1;
              z-index: 99;
            visibility: visible;
            }

          .info h3 {
                padding: 0;
                color: #ffffff;
            }

          .info .file {
                padding-bottom: 20px;
                max-height: 180px;
                overflow: auto;
            }

          .info .file p {
                margin: 0;
            }

          .info img {
                margin-top: 30px;
            }

          @media (min-width:800px) {
              .info {
                  transform: translate(100%, -50%);
              }
          }
            @media (min-width:1600px) {
                .space h3, .space h4 {
                    font-size: 40px;
                }
                .space h3 {
                    margin-bottom: 15px;
                }
            }
    `
    ],
    providers: [FileSizePipe]
})
export class UploadComponent {
    public availableStorage: number;
    public files: Array<File> = [];
    public notAllowed: boolean;
    public sizeNotAllowed: boolean;
    public double: Array<string> = [];
    public count: number = -1;
    public completeCount: number = 0;
    public activeFile: FileComponent;
    public timeOut: any;
    public complete: boolean = false;
    public reupload: boolean = false;
    public maxSize: number = 512;
    @Input() storage: Storage;
    @Input() progress: Observable<number>;
    @Input() success: Observable<boolean>;
    @Input() error: Observable<string>;
    @Output() upload = new EventEmitter<File>();
    @Output() logout = new EventEmitter();
    @ViewChildren('cmp') components: QueryList<FileComponent>;

    constructor(public fileSizePipe: FileSizePipe) {}

    ngOnInit() {
        this.progress.subscribe((progress: number) => {
            if (this.activeFile) {
                this.activeFile.progress = progress;
            }
        });
        this.success.subscribe((success: boolean) => {
            if (success) {
                this.activeFile.class = "done";
                this.activeFile.status = "Przesłano poprawnie";
                this.completeCount++;
                this.isNext();
            }
        });

        this.error.subscribe((error: string) => {
            if (error) {
                this.activeFile.class = "warning";
                this.activeFile.status = "Wystąpił błąd";
                this.isNext();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        var simpleChange: SimpleChange = changes["storage"];
        if (simpleChange && !simpleChange.firstChange) {
            this.availableStorage = this.storage.available - this.storage.used;
        }
    }

    selectChange(files: FileList): void {
       console.log(files[0]);
       this.double = [];
       this.notAllowed = false;
       this.sizeNotAllowed = false;

       const allowed: Array<string> = ['png', 'jpg', 'jpeg', 'mp4'];
       for (let i = 0; i < files.length; i++) {
            const file: File = files.item(i);
            if (file.size / 1000000 >  this.maxSize) {
                this.sizeNotAllowed = true;
            }
            if (!this.sizeNotAllowed && allowed.includes(file.name.split('.').pop().toLowerCase())) {
                if (!this.files.some(data => data.name === file.name)) {
                    this.files.push(file);
                    this.availableStorage -= this.fileSizePipe.transform(file.size);
                }
                else {
                    this.double.push(file.name);
                }
            }
            else if (!allowed.includes(file.name.split('.').pop().toLowerCase())) {
                this.notAllowed = true;
            }
        }
       if (this.availableStorage < 0) {
            this.availableStorage = 0;
        }
       if (this.double.length > 0 || this.notAllowed || this.sizeNotAllowed) {
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(() => {
                this.double = [];
                this.notAllowed = false;
                this.sizeNotAllowed = false;
            }, 10000);
        }
    }

    remove(id: number): void {
        this.files.splice(id, 1);
        this.availableStorage = this.storage.available - this.storage.used;
        for (let i = 0; i < this.files.length; i++) {
            let file: File = this.files[i];
            this.availableStorage -= this.fileSizePipe.transform(file.size);
        }
        if (this.availableStorage < 0) {
            this.availableStorage = 0;
        }
    }

    uploadStart(): void {
        this.count = -1;
        this.completeCount = 0;
        this.changeStatus();
        this.next();
    }

    changeStatus() {
        for (let file of this.components.toArray()) {
            let fileComponent: FileComponent = file;
            fileComponent.class = "in-progress";
        }
    }

    next(): void {
        this.count++;
        this.activeFile = this.components.toArray()[this.count];
        this.upload.emit(this.files[this.count]);
    }

    isNext() {
        if (!this.reupload) {
            if (this.count < this.files.length - 1) {
                this.next();
            }
        }
        if (this.completeCount === this.files.length) {
            this.complete = true;
        }
        this.reupload = false;
    }

    startReupload(id: number): void {
        this.reupload = true;
        this.activeFile = this.components.toArray()[id];
        this.activeFile.class = "in-progress";
        this.upload.emit(this.files[id]);
    }
}
