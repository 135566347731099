import {UploadApiActions, UploadPageActions} from '../actions';
import {Storage} from '../models/storage';
import {createReducer, on} from '@ngrx/store';
import {AuthActions, AuthApiActions} from '../../auth/actions';

export interface State {
    storage: Storage | null,
    progress: number | null,
    success: boolean | null;
    error: string | null;
}

export const initialState: State = {
    storage: null,
    progress: null,
    success: false,
    error: null
};

export const reducer = createReducer(
    initialState,
    on(UploadPageActions.GetStorage, (state: State, action) => ({...state, success: false})),
    on(UploadApiActions.StorageSuccess, (state: State, action) => ({...state, storage: action.payload})),
    on(UploadApiActions.StorageFailure, (state: State, action) => ({...state, storage: null})),
    on(UploadPageActions.Upload, (state: State, action) =>  ({...state, progress : 0, success: false})),
    on(UploadApiActions.UploadProgress, (state: State, action) =>  ({...state, progress: action.payload.progress})),
    on(UploadApiActions.UploadSuccess, (state: State, action) =>  ({...state,  progress: 100, success: true, error: null})),
    on(UploadApiActions.UploadFailure, (state: State, action) =>  ({...state,   progress: null,  error: action.payload.error})),
);



// export function reducer(
//     state = initialState,
//     action:
//         | UploadApiActions.UploadApiActionsUnion
//         | UploadPageActions.UploadPageActionsUnion
// ): State {
//     switch (action.type) {
//         case UploadPageActions.UploadPageActionTypes.GetStorage: {
//             return {
//                 ...state,
//                 success: false
//             };
//         }
//         case UploadApiActions.UploadApiActionTypes.StorageSuccess: {
//             return {
//                 ...state,
//                 storage: action.payload
//             };
//         }
//         case UploadApiActions.UploadApiActionTypes.StorageFailure: {
//             return {
//                 ...state,
//                 storage: null
//             };
//         }
//         case UploadPageActions.UploadPageActionTypes.Upload: {
//             return {
//                 ...state,
//                 progress: 0,
//                 success: false
//             };
//         }
//
//         case UploadApiActions.UploadApiActionTypes.UploadProgress: {
//             return {
//                 ...state,
//                 progress: action.payload.progress
//             };
//         }
//
//         case UploadApiActions.UploadApiActionTypes.UploadSuccess: {
//             return {
//                 ...state,
//                 progress: 100,
//                 success: true,
//                 error: null
//             };
//         }
//
//         case UploadApiActions.UploadApiActionTypes.UploadFailure: {
//             return {
//                 ...state,
//                 error: action.payload.error,
//                 progress: null
//             };
//         }
//
//         default: {
//             return state;
//         }
//     }
// }

export const getStorage = (state: State) => state.storage;

export const getProgress = (state: State) => state.progress;

export const getSuccess = (state: State) => state.success;

export const getError = (state: State) => state.error;
