import {Component, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {AuthActions} from '../../auth/actions';
import * as fromRoot from '../../reducers';
import * as fromAuth from '../../auth/reducers';
import * as fromLayout from '../../core/reducers';
import {Observable} from 'rxjs';
import {I18nActions, LayoutActions} from '../actions';

@Component({
    selector: 'app-root',
    template: `
      <div class="page">
      <header>
        <img src="assets/img/logo_transfer.svg" alt="logo">
        <span class="logout" (click)="logout()" *ngIf="loggedIn$ | async">{{ 'HEADER/LOGOUT' | translate }}</span>

          <div class="sec-center">
              <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
              <label class="for-dropdown" for="dropdown">{{ currentLang  }} <i class="uil uil-arrow-down"></i></label>
              <div class="section-dropdown">
                  <a href="/pl">pl<i class="uil uil-arrow-right"></i></a>
                  <a href="/en">en<i class="uil uil-arrow-right"></i></a>
                  <a href="/de">de<i class="uil uil-arrow-right"></i></a>
                  <a href="/cs">cs<i class="uil uil-arrow-right"></i></a>
                  <a href="/sk">sk<i class="uil uil-arrow-right"></i></a>
              </div>
          </div>



      </header>

    <router-outlet></router-outlet>
      <footer>
        <div>
          <a href="mailto:hello@eminto.eu">hello@eminto.eu</a>
          <a *ngIf="currentLang  === 'pl' "  href="tel:+48 22 602 22 11">(22 602 22 11)</a>
          <a *ngIf="currentLang  !== 'pl' "  href="tel:+1202 221 2303">(+1202 221 2303)</a>
        </div>
        <div>
          <p>Jodłowa 27/3</p>
          <p>02-907 Warszawa</p>
        </div>
        <div style="display: flex">
            <a href="https://www.facebook.com/eminto.eu"><img src="assets/img/icons/FB.svg" alt="logo-fb"></a>
            <a href="https://www.instagram.com/eminto.pl/"><img src="assets/img/icons/IG.svg" alt="logo-i"></a>
            <a href="https://www.youtube.com/channel/UCQx-8cy2q_THoLKYwDTEUFg"><img src="assets/img/icons/YT.svg" alt="logo-yt"></a>
            <a href="https://m.me/eminto.eu"><img src="assets/img/icons/Messenger.svg" alt="logo-messanger"></a>
        </div>

      </footer>
      </div>
    `
})
export class AppComponent implements  OnInit{
    loggedIn$: Observable<boolean>;
    currentLang$: Observable<string>;
    currentLang = 'pl';
    constructor(private store: Store<fromRoot.State>) {
        this.loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
        this.currentLang$ = this.store.pipe(select(fromLayout.getCurrentLanguage));
    }

    ngOnInit(): void {
        this.store.dispatch(LayoutActions.setTranslationInComplate());
        this.store.dispatch(I18nActions.getHash());
        this.currentLang$.subscribe(lang => this.currentLang = lang);
    }

    logout() {
        this.store.dispatch(AuthActions.Logout());
    }
}
