import { createReducer, on } from '@ngrx/store';
import {I18nActions, LayoutActions} from '../actions';

export const layoutFeatureKey = 'layout';

export interface State {
  translationHash: string | null;
  currentLanguage: string;
  loading: boolean;
  isTranslationComplete: boolean;

}

const initialState: State = {
  translationHash: null,
  currentLanguage: 'en',
  loading: false,
  isTranslationComplete: false,
};


export const reducer = createReducer(
  initialState,
  on(I18nActions.setHash, (state, action) => ({...state, translationHash: action.hash})),
  on(I18nActions.setLanguageSuccess, (state, action) => ({...state, currentLanguage: action.language})),
  on(LayoutActions.setTranslationComplate, (state, action) => ({ ...state, isTranslationComplete: true})),
  on(LayoutActions.setTranslationInComplate, (state, action) => ({ ...state, isTranslationComplete: false})),
);

export const getLanguage = (state: State) => state.currentLanguage;
export const isloading = (state: State) => state.loading;
export const isTranslationComplete = (state: State) => state.isTranslationComplete;
