import { setTranslationComplate } from './../actions/layout.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { I18nActions, LayoutActions } from '../actions';
import { I18nService } from '../services/i18n.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromRoot from '../../reducers';
import * as fromLayout from '../reducers/layout.reducer';

@Injectable()
export class I18nEffects {
  getHash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(I18nActions.getHash),
      switchMap(() =>
        this.i18nService.getHash().pipe(
          map((hash: string) => I18nActions.getHashSuccess({ hash })),
          catchError((error: string) =>
            of(I18nActions.getHashFailure({ error: error.toString() }))
          )
        )
      )
    )
  );

  getHashSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(I18nActions.getHashSuccess),
      withLatestFrom(this.storeLayout),
      switchMap(([action, state]) => {
        if (state.translationHash !== action.hash) {
          this.store.dispatch(I18nActions.setHash({ hash: action.hash }));
          return of(I18nActions.getTranslationDictionary());
        }
        return of(I18nActions.loadTranslationCache());
      })
    )
  );

  getTranslationDictionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(I18nActions.getTranslationDictionary),
      switchMap(() =>
        this.i18nService.getTranslations().pipe(
          map((dictionary: any) =>
            I18nActions.getTranslationDictionarySuccess({ dictionary })
          ),
          catchError((error: string) =>
            of(I18nActions.getTranslationDictionaryFailure({ error }))
          )
        )
      )
    )
  );

  setLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(I18nActions.setLanguage),
      withLatestFrom(this.storeLayout),
      switchMap(([action, state]) => {
        if (action.language !== state.currentLanguage) {
          this.translateService.use(action.language);
        }
        return of(I18nActions.setLanguageSuccess({language: action.language}));
      })
    )
  );

  getTranslationDictionarySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(I18nActions.getTranslationDictionarySuccess),
      switchMap((dictionary) => {
        this.storeLayout.dispatch(LayoutActions.setTranslationComplate())
        for (const [lang, dict] of Object.entries(dictionary.dictionary)) {
          const typedDict: any = dict;
          this.translateService.setTranslation(lang, typedDict);
        }
        localStorage.setItem(
          'translations',
          JSON.stringify(dictionary.dictionary)
        );
        return of(dictionary);
      })
    ), {dispatch: false}
  );

  getTranslationCache$ = createEffect(() =>
  this.actions$.pipe(
    ofType(I18nActions.loadTranslationCache),
    switchMap(() => {
       this.storeLayout.dispatch(LayoutActions.setTranslationComplate())
      const cache = JSON.parse(localStorage.getItem('translations')!);
      for (const [lang, dict] of Object.entries(cache)) {
        const typedDict: any = dict;
        this.translateService.setTranslation(lang, typedDict);
      }
      return of(cache);
    })
  ), {dispatch: false}
);

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private storeLayout: Store<fromLayout.State>,
    private i18nService: I18nService,
    private translateService: TranslateService,
  ) {}
}
