import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';

export class Utils {

    public static validateFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl && !control.valid) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof UntypedFormGroup) {
                Utils.validateFormFields(control);
            }
        });
    }
    public static isJson(str: string) {
        try {
            var json = JSON.parse(str);
            return json;
        } catch (e) {
            return str;
        }
    }
}