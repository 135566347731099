import {Component} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {UploadPageActions} from '../actions';
import * as fromUpload from '../reducers';
import {AuthActions} from 'src/app/auth/actions';

@Component({
    template: `
    <app-upload
      (upload)="upload($event)"
      (logout)="logout()"
      [storage]="storage$ | async"
      [progress]="progress$"
      [success]="success$"
      [error]="error$">
    </app-upload>`
})
export class UploadPageComponent {
    storage$ = this.store.pipe(select(fromUpload.getStorage));
    progress$ = this.store.pipe(select(fromUpload.getProgress));
    success$ = this.store.pipe(select(fromUpload.getSuccess));
    error$ = this.store.pipe(select(fromUpload.getError));

    constructor(private store: Store<fromUpload.State>) {
        this.store.dispatch(UploadPageActions.GetStorage());
    }

    upload(file: File) {
        this.store.dispatch(UploadPageActions.Upload({payload: file}));
    }

    logout() {
        this.store.dispatch(AuthActions.Logout());
    }
}
