import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromUploadPage from './upload.reducer';
import {UploadApiActions} from '../actions';

export interface UploadState {
    uploadPage: fromUploadPage.State;
}

export interface State extends fromRoot.State {
    upload: UploadState;
}

export const reducers: ActionReducerMap<UploadState> = {
    uploadPage: fromUploadPage.reducer
};

export const selectUploadState = createFeatureSelector<State, UploadState>('upload');

export const selectUploadPageState = createSelector(
    selectUploadState,
    (state: UploadState) => state.uploadPage
);

export const getStorage = createSelector(
    selectUploadPageState,
    fromUploadPage.getStorage
);

export const getProgress = createSelector(
    selectUploadPageState,
    fromUploadPage.getProgress
);

export const getSuccess = createSelector(
    selectUploadPageState,
    fromUploadPage.getSuccess
);

export const getError = createSelector(
    selectUploadPageState,
    fromUploadPage.getError
);
