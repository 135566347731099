import { createAction, props} from '@ngrx/store';
import { Token } from '../models/auth';

export const ClientCredentialsToken = createAction(
    '[Auth] Client Credentials Token'
);
export const  RefreshToken  = createAction(
    '[Auth] Refresh Token',
    props<{payload: Token}>()
);
export const  Logout  = createAction(
    '[Auth] Logout',
);


// export enum AuthActionTypes {
//     Logout = '[Auth] Logout',
//     RefreshToken = '[Auth] Refresh Token',
//     ClientCredentialsToken = '[Auth] Client Credentials Token'
// }

// export class ClientCredentialsToken implements Action {
//     readonly type = AuthActionTypes.ClientCredentialsToken;
// }
//
// export class RefreshToken implements Action {
//     readonly type = AuthActionTypes.RefreshToken;
//     constructor(public payload: Token) {}
// }
//
// export class Logout implements Action {
//     readonly type = AuthActionTypes.Logout;
// }

// export type AuthActionsUnion = Logout | ClientCredentialsToken | RefreshToken;
